import Quality from "../assets/quality.png";
import Verizon from "../assets/verizon.jpg";
import ProjectImg from "../assets/Fucshia_Home_Assistant.png";
interface Map {
  [key: string]: string | undefined;
}

// google : Google
// google - company name mentioned in Experience.json (lower cased)
// Google - imported nam
export const logo: Map = {
  quality: Quality,
  verizon: Verizon,
};

export enum Constants {
  USERNAME = "Mateus Seiboth",
}

// "project name 1": ProjectImg
// "project name 1" - project name mentioned in Projects.json (lower cased)
// ProjectImg - imported name 
export const projectImage: Map = {
  "fucshia home assistant": ProjectImg,
  
};
